import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCss3,
  faGit,
  faHtml5,
  faJs,
  faLaravel,
  faReact,
} from '@fortawesome/free-brands-svg-icons'

import AnimatedLetters from '../AnimatedLetters'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              strArray={'About me'.split('')}
              idx={13}
              letterClass={letterClass}
            />
          </h1>

          <p>
            Hi, I'm Kynt Tabudlong! Software engineer with 5 years working
            experience on web development as a full stack developer.
          </p>

          <p>
            Originally from the medical field, but later realized the tech
            industry is my calling. I want to build stuff, experiment on the
            what ifs, and conceptualize solutions for daily convenience. Always
            curious and ever want to expand my knowledge, may it be technical,
            or mechanical.
          </p>

          <p>
            Started my web development journey on 2018. Was tasked to maintain a
            homegrown product (dogfooding). Fixing bugs, new features,
            customizations, upgrade scripts for mysql migrations. This was
            during my first 3 years as a web developer, learning the SOLID
            principles slowly integrating them into coding habits.
          </p>

          <p>
            Later on, decided to learn ReactJS, Typescript, Laravel. Building
            applications using these tech, learning design patterns, migrating
            applications to a better architecture, all attributed to my
            skillset.
          </p>

          <p>
            A gamer by heart. I love playing games (JRPGs, Action, Adventure,
            Fighting). This type of games stimulate my problem solving skills,
            ang generally molded my competetive nature.Always strive for the
            best, its go BIG or go home, on in betweens.
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#2884D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faLaravel} color="#DD0031" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJs} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGit} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>

      <Loader type="pacman" />
    </>
  )
}

export default About
